<template>
    <component :is="component" class="guideline-card" v-bind="attributes">
        <h5 class="guideline-card__title"><slot /></h5>
    </component>
</template>

<script>
import uriVue from '~/mixins/uri.vue';
export default {
    mixins: [
        uriVue
    ],

    props: {
        url: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        isExternal() {
            return this.checkIsExternal(this.url);
        },

        component() {
            if (!this.url) {
                return 'article';
            }
            return this.isExternal ? 'a' : 'nuxt-link';
        },

        attributes() {
            if (!this.url) {
                return null;
            }
            if (this.isExternal) {
                return {
                    href: this.url,
                    target: '_blank',
                    rel: 'noopener noreferrer'
                };
            }
            return {
                to: this.url
            };
        }
    }
};
</script>

<style lang="less" src="./guideline-card.less" />
