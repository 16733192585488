<template>
    <a v-if="urlIsExternal" :href="url" target="blank" rel="noopener noreferrer" class="banner">
        <img v-if="image" class="banner__image" :src="image.src" alt="">
        <div class="banner__content">
            <slot />
        </div>
        <uro-icon class="banner__arrow" icon="arrow-right" />
    </a>

    <nuxt-link v-else :to="url" class="banner">
        <img v-if="image" class="banner__image" :src="image.src" alt="">
        <div class="banner__content">
            <slot />
        </div>
        <uro-icon class="banner__arrow" icon="arrow-right" />
    </nuxt-link>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            required: true,
            default: ''
        },
        image: {
            type: Object,
            required: false,
            default: null
        }
    },

    computed: {
        urlIsExternal() {
            return this.url && this.url.startsWith('http');
        }
    }
};
</script>

<style lang="less" src="./banner.less" />
