<template>
    <div v-if="!isLoading && cards.length" class="guideline-cards">
        <guideline-card v-for="(card, index) in cards" :key="index" :url="card.url">
            {{ card.title }}
        </guideline-card>
    </div>

    <div v-else class="guideline-cards">
        <guideline-card v-for="i in [1,2,3,4,5,6,7,8]" :key="i">
            <content-placeholders :rounded="true">
                <content-placeholders-text :lines="2" />
            </content-placeholders>
        </guideline-card>
    </div>
</template>

<script>
import GuidelineCard from '~/patterns/atoms/guideline-card/guideline-card.vue';

export default {
    components: {
        GuidelineCard
    },

    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        cards: {
            type: Array,
            required: false,
            default: () => []
        }
    }
};
</script>

<style lang="less" src="./guideline-cards.less" />
