<template>
    <div v-if="items && items.length" class="banners">
        <uro-banner
            v-for="(item, index) in items"
            :key="index"
            :url="`/${item.uri}`"
            :image="item.logo ? item.logo[0] : null"
            :class="{'banner--secondary' : secondaryStyle}"
        >
            {{ item.title }}
        </uro-banner>
    </div>
</template>

<script>
import UroBanner from '~/patterns/atoms/banner/banner.vue';

export default {
    components: {
        UroBanner
    },

    props: {
        items: {
            type: Array,
            required: true,
            default: () => []
        },
        secondaryStyle: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="less" src="./banners.less" />
